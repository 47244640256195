<template>
  <div id="donutoptions">
    <h4>Donut Size (%)</h4>
    <div class="block">
      <el-slider
        @change="updateChart"
        v-model="plotOptions.pie.donut.size"
        :max="100"
        :min="0"
        show-input
      ></el-slider>
    </div>

    <h4>Start Angle</h4>
    <div class="block">
      <el-slider
        @change="updateChart"
        v-model="plotOptions.pie.startAngle"
        :max="360"
        :min="-360"
        show-input
      ></el-slider>
    </div>

    <h4>End Angle</h4>
    <div class="block">
      <el-slider
        @change="updateChart"
        v-model="plotOptions.pie.endAngle"
        :max="360"
        :min="-360"
        show-input
      ></el-slider>
    </div>

    <el-form-item label="Inner Color">
      <extended-el-color-picker
        @change="updateChart"
        v-model="plotOptions.pie.donut.background"
      ></extended-el-color-picker>
    </el-form-item>

    <el-collapse>
      <el-collapse-item title="Inner Data Labels">
        <el-tooltip
          class="item"
          effect="dark"
          content="Show inner labels when user hovers over the donut slices"
          placement="left-start"
        >
          <el-form-item label="Show">
            <el-switch
              @change="updateChart"
              inactive-color="#24272b"
              v-model="plotOptions.pie.donut.labels.show"
            ></el-switch>
          </el-form-item>
        </el-tooltip>

        <el-collapse>
          <el-collapse-item title="Category / Name">
            <el-form-item label="Color">
              <extended-el-color-picker
                @change="updateChart"
                v-model="plotOptions.pie.donut.labels.name.color"
              ></extended-el-color-picker>
            </el-form-item>

            <h4>Font Size</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="plotOptions.pie.donut.labels.name.fontSize"
                :min="2"
                :max="80"
                show-input
              ></el-slider>
            </div>

            <h4>Font Weight</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="plotOptions.pie.donut.labels.name.fontWeight"
                :min="100"
                :step="100"
                :max="900"
              ></el-slider>
            </div>
          </el-collapse-item>
        </el-collapse>

        <el-collapse>
          <el-collapse-item title="Value">
            <el-form-item label="Color">
              <extended-el-color-picker
                @change="updateChart"
                v-model="plotOptions.pie.donut.labels.value.color"
              ></extended-el-color-picker>
            </el-form-item>

            <h4>Font Size</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="plotOptions.pie.donut.labels.value.fontSize"
                :min="2"
                :max="80"
                show-input
              ></el-slider>
            </div>

            <h4>Font Weight</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="plotOptions.pie.donut.labels.value.fontWeight"
                :min="100"
                :step="100"
                :max="900"
              ></el-slider>
            </div>
          </el-collapse-item>
        </el-collapse>

        <el-collapse>
          <el-collapse-item title="Total">
            <el-form-item label="Show Total">
              <el-switch
                @change="updateChart"
                inactive-color="#24272b"
                v-model="plotOptions.pie.donut.labels.total.show"
              ></el-switch>
            </el-form-item>

            <el-form-item label="Color">
              <extended-el-color-picker
                @change="updateChart"
                v-model="plotOptions.pie.donut.labels.total.color"
              ></extended-el-color-picker>
            </el-form-item>

            <h4>Font Size</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="plotOptions.pie.donut.labels.total.fontSize"
                :min="2"
                :max="80"
                show-input
              ></el-slider>
            </div>

            <h4>Font Weight</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="plotOptions.pie.donut.labels.total.fontWeight"
                :min="100"
                :step="100"
                :max="900"
              ></el-slider>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-collapse-item>
    </el-collapse>

    <el-collapse>
      <el-collapse-item title="Padding">
        <h4>Top</h4>
        <div class="block">
          <el-slider
            @change="updateChart"
            v-model="grid.padding.top"
            :max="300"
            :min="-300"
            show-input
          ></el-slider>
        </div>
        <h4>Right</h4>
        <div class="block">
          <el-slider
            @change="updateChart"
            v-model="grid.padding.right"
            :max="300"
            :min="-300"
            show-input
          ></el-slider>
        </div>
        <h4>Bottom</h4>
        <div class="block">
          <el-slider
            @change="updateChart"
            v-model="grid.padding.bottom"
            :max="300"
            :min="-300"
            show-input
          ></el-slider>
        </div>
        <h4>Left</h4>
        <div class="block">
          <el-slider
            @change="updateChart"
            v-model="grid.padding.left"
            :max="300"
            :min="-300"
            show-input
          ></el-slider>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  data() {
    return {
      plotOptions: {
        pie: {
          startAngle: 0,
          endAngle: 360,
          donut: {
            size: 65,
            background: undefined,
            labels: {
              // These are the inner labels appearing inside donut
              show: false,
              name: {
                show: true,
                fontSize: 16,
                color: undefined,
                offsetY: -10,
              },
              value: {
                show: true,
                fontSize: 20,
                color: undefined,
                offsetY: 10,
              },
              total: {
                show: false,
                showAlways: false,
                label: "Total",
                fontSize: 16,
                color: undefined,
              },
            },
          },
        },
      },
    };
  },
  watch: {
    chartConfig: function(newProp) {
      const p = newProp.plotOptions;
      this.setNewOptions(p);
    },
  },
  props: {
    grid: {
      type: Object,
      required: true,
    },
  },
  mounted: function() {
    const p = this.getChartConfig().w.config.plotOptions;
    this.setNewOptions(p);
  },
  methods: {
    setNewOptions(p) {
      this.plotOptions = {
        pie: {
          startAngle: p.pie.startAngle,
          endAngle: p.pie.endAngle,
          donut: {
            size: parseInt(p.pie.donut.size),
            background: p.pie.donut.background,
            labels: {
              show: p.pie.donut.labels.show,
              name: {
                show: p.pie.donut.labels.name.show,
                fontSize: p.pie.donut.labels.name.fontSize,
                fontWeight: p.pie.donut.labels.name.fontWeight,
                color: p.pie.donut.labels.name.colors,
                offsetY: p.pie.donut.labels.name.offsetY,
              },
              value: {
                show: p.pie.donut.labels.value.show,
                fontSize: p.pie.donut.labels.value.fontSize,
                fontWeight: p.pie.donut.labels.value.fontWeight,
                color: p.pie.donut.labels.value.color,
                offsetY: p.pie.donut.labels.value.offsetY,
              },
              total: {
                show: p.pie.donut.labels.total.show,
                label: p.pie.donut.labels.total.label,
                fontSize: p.pie.donut.labels.total.fontSize,
                fontWeight: p.pie.donut.labels.total.fontWeight,
                color: p.pie.donut.labels.total.color,
              },
            },
          },
        },
      };
    },
    updateChart: function() {
      const p = this.plotOptions;
      this.updateChartOptions({
        grid: {
          padding: {
            top: this.grid.padding.top,
            left: this.grid.padding.left,
            right: this.grid.padding.right,
            bottom: this.grid.padding.bottom,
          },
        },
        plotOptions: {
          pie: {
            startAngle: p.pie.startAngle,
            endAngle: p.pie.endAngle,
            donut: {
              size: p.pie.donut.size.toString() + "%",
              background: p.pie.donut.background,
              labels: {
                show: p.pie.donut.labels.show,
                name: {
                  show: p.pie.donut.labels.name.show,
                  fontSize: p.pie.donut.labels.name.fontSize,
                  fontWeight: p.pie.donut.labels.name.fontWeight,
                  color: p.pie.donut.labels.name.colors,
                  offsetY: p.pie.donut.labels.name.offsetY,
                },
                value: {
                  show: p.pie.donut.labels.value.show,
                  fontSize: p.pie.donut.labels.value.fontSize,
                  fontWeight: p.pie.donut.labels.value.fontWeight,
                  color: p.pie.donut.labels.value.color,
                  offsetY: p.pie.donut.labels.value.offsetY,
                },
                total: {
                  show: p.pie.donut.labels.total.show,
                  label: p.pie.donut.labels.total.label,
                  fontSize: p.pie.donut.labels.total.fontSize,
                  fontWeight: p.pie.donut.labels.total.fontWeight,
                  color: p.pie.donut.labels.total.color,
                },
              },
            },
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
